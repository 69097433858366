import React, { useState } from 'react'
import { Box, InputBase, styled } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Comment, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { useAuthState, isEmptyOrWhitespace, isWhitespace } from 'packages/core'
import { useReplyComment } from 'hooks'
import { smallScreenWidth, COMMENT_MAX_LENGTH } from 'utils'
import useSubcomponents from 'useSubcomponents'
import NoDataFoundMessage from 'packages/eid-ui/NoDataFoundMessage'

const StyledInput = withStyles(() => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        border: 'solid 1px #d8d8dd',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        '&:hover': {
            border: 'solid 1px #307fc1',
        },
        '&:focus-within': {
            boxShadow: '0 4px 8px 0 rgba(48, 127, 193, 0.33)',
            border: 'solid 1px #307fc1 !important',
        },
    },
    input: {
        position: 'relative',
        fontSize: 15,
        lineHeight: 1.33,
        color: '#8b909a',
        padding: '3px 15px',
    },
    error: {
        border: 'solid 1px #f44336 !important',
        '&:focus-within': {
            border: 'solid 1px #f44336 !important',
        },
    },
}))(InputBase)

const StyledContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem',
    paddingRight: '0',
    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column',
    },
})

const StyledButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [`@media (max-width:${smallScreenWidth})`]: {
        paddingTop: '1.6rem',
        maxWidth: '10rem',
    },
})

const StyledComment = styled(Box)({
    position: 'absolute',
    bottom: '-2.0rem',
    right: 2,
    fontSize: '1rem',
})

const AddNewComment = ({ handleAddComment, loading }) => {
    const { t } = useTranslation()
    const [inputText, setInputText] = useState('')

    const handleChange = (event) => {
        const keyword = event.target.value
        setInputText(keyword)
    }

    const notSubmitable =
        inputText.length > COMMENT_MAX_LENGTH ||
        isEmptyOrWhitespace(inputText) ||
        loading

    const handleKeyDown = (event) => {
        if (event.which === 13 && !notSubmitable) {
            handleAddComment(inputText)
            setInputText('')
            event.preventDefault()
        }
    }

    return (
        <StyledContainer>
            <Box width="100%" marginRight="15px" position="relative">
                <StyledComment>
                    {inputText.length}/{COMMENT_MAX_LENGTH}
                </StyledComment>
                <StyledInput
                    fullWidth
                    multiline
                    value={inputText}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('AddAComment')}
                    error={
                        inputText.length > COMMENT_MAX_LENGTH ||
                        isWhitespace(inputText)
                    }
                />
            </Box>
            <StyledButtonContainer>
                <Button.Send
                    loading={loading}
                    disabled={notSubmitable}
                    onClick={() => {
                        handleAddComment(inputText)
                        setInputText('')
                    }}
                >
                    {t('Send')}
                </Button.Send>
            </StyledButtonContainer>
        </StyledContainer>
    )
}

const CommentsList = (props) => {
    const {
        page,
        loading,
        fallbackMessage = 'NoCommentsFound',
        handleAddComment,
        isSubmitting,
        list,
        requestId,
        itemId,
        commentsContainerProps,
        handleEditComment,
        handleDeleteComment,
    } = props

    const [replyComment] = useReplyComment(page, requestId, itemId)

    const [{ currentUserId }] = useAuthState()
    const { t } = useTranslation()

    const {
        hasAccessDeleteCommentControl,
        hasAccessEditCommentControl,
    } = useSubcomponents()

    return (
        <Box width="100%" padding="15px">
            {loading && (
                <>
                    <Box display="flex" justifyContent="flex-end">
                        <Comment loading={true} myComment />
                    </Box>
                    <Box display="flex">
                        <Comment loading={true} />
                    </Box>
                </>
            )}

            <Box width="100%" {...commentsContainerProps}>
                {list && (
                    <>
                        {list.length === 0 ? (
                            <Box
                                padding="15px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <NoDataFoundMessage message={t(fallbackMessage)} />
                            </Box>
                        ) : (
                            list
                                .filter((f) => !Boolean(f.parentId))
                                .map((c, index) => {
                                    const commentProps = {
                                        shouldAllowReplies: !Boolean(
                                            c.parentId,
                                        ),
                                        replies: list.filter(
                                            (l) => l.parentId === c.id,
                                        ),
                                        ...c,
                                    }
                                    return c.commentorPersonId ===
                                        currentUserId ? (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            key={`${index}${c.id}`}
                                        >
                                            <Comment
                                                {...commentProps}
                                                myComment
                                                handleReply={(d) => {
                                                    replyComment({
                                                        parentId: c.id,
                                                        value: d,
                                                    })
                                                }}
                                                handleDeleteComment={
                                                    hasAccessDeleteCommentControl &&
                                                    handleDeleteComment
                                                }
                                                handleEditComment={
                                                    hasAccessEditCommentControl &&
                                                    handleEditComment
                                                }
                                            />
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            key={`${index}${c.id}`}
                                        >
                                            <Comment
                                                {...commentProps}
                                                handleReply={(d) => {
                                                    replyComment({
                                                        parentId: c.id,
                                                        value: d,
                                                    })
                                                }}
                                                handleDeleteComment={
                                                    hasAccessDeleteCommentControl &&
                                                    handleDeleteComment
                                                }
                                                handleEditComment={
                                                    hasAccessEditCommentControl &&
                                                    handleEditComment
                                                }
                                            />
                                        </Box>
                                    )
                                })
                        )}
                    </>
                )}
            </Box>

            <AddNewComment
                handleAddComment={handleAddComment}
                loading={isSubmitting}
            />
        </Box>
    )
}

export default CommentsList
